import React, { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import { json, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../components/loading/Loading';
import Header from '../layout/Header';

const TokenLogin = () => {
    const { userId, schoolCode, navigate, img_url, getSchoolInformation, schoolData, isLoading, sessionType, setCartLength, api_url, schoolSetData, setIsLoading, getSchoolSet, setIsAuthenticated } = useGlobalContext();
    const [tokendata, setTokenData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('tokenlogin');
    const [hasValidated, setHasValidated] = useState(false);
    const hasRunRef = useRef(false);
    useEffect(() => {
        if (token && !hasRunRef.current) {
            hasRunRef.current = true; // Set the ref to true to prevent further calls
            getTokenValidation();
        }
    }, [token]);

    const getTokenValidation = async () => {
        const data = { token };
        setIsLoading(true);

        try {
            const res = await axios.post(`${api_url}/tokenvalidation`, data);
            if (res.data.success === 1) {
                let loginData = res.data.data;
                setTokenData(res.data.data);
                setIsLoading(false);
                toast.success(res.data.message, {
                    theme: "colored",
                    autoClose: 2000,
                });
                const timer = setTimeout(() => {
                    sessionStorage.setItem('token', (loginData.token));
                    sessionStorage.setItem('username', loginData.name);
                    loginData.school_code !== null && sessionStorage.setItem('school_code', loginData.school_code);
                    sessionStorage.removeItem('guest_id')
                    sessionStorage.setItem('user_id', loginData.user_id);
                    // if (loginData.school_code !== null)
                    //     navigate('/myschool')
                    // else navigate('/')
                    window.scrollTo({ top: 0, behavior: 'instant' })
                }, 3000);
                return () => clearTimeout(timer);
            } else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored',
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className='bg-transparent'>
            {JSON.stringify(token)}
            {isLoading ? <div
                style={{ height: "100vh" }}
                className="d-flex gap-2 flex-column position-fixed top-0 bottom-0 start-0 loading end-0 align-items-center justify-content-center"
            >
                <div className="spinner-border text-danger" role="status"></div>
                <span class="fs-5 text-danger">Validating Token ...</span>
            </div> : `  ${JSON.stringify(token)}`}
            {/* <Header/> */}
            {/* {console.log(tokendata)} */}
        </div>
    )
}

export default TokenLogin